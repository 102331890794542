import { MobileNavItem } from "./MobileNavItem";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import type { INavigation } from "@contentful-api/types/contentful";
import { mobileMenuContainerStyles } from "@components/layouts/navigation/styles";
import { useState } from "react";

export interface MobileNavProps {
    navigation: INavigation;
}

export const MobileNav = ({ navigation }: MobileNavProps) => {
    const [parentMenuBg, setParentMenuBg] = useState(false);

    const handleFirstMenuBgColor = () => {
        setParentMenuBg(!parentMenuBg);
    };

    return (
        <>
            <Box>
                <Flex
                    bg={parentMenuBg ? "transparent" : "white"}
                    transition={parentMenuBg && "background-color .2s .1s"}
                    sx={mobileMenuContainerStyles}
                >
                    {navigation?.fields?.navigationItems?.map((navigationItem) => (
                        <MobileNavItem
                            key={navigationItem?.sys?.id}
                            navigationItem={navigationItem}
                            handleFirstMenuBgColor={handleFirstMenuBgColor}
                        />
                    ))}
                </Flex>
            </Box>
        </>
    );
};
