import type { Document, Text } from "@contentful/rich-text-types";

export const removeEmptyContent = (doc: Document): Document => {
    return {
        ...doc,
        content: doc.content.filter(
            (c) => c.content.filter((cc) => !!(cc as unknown as Text).value).length
        ),
    };
};
