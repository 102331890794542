import { space } from "@ui/design-tokens";

export const styles = {
    container: {
        minWidth: "23.33%",
        fontSize: "mb",
        fontWeight: "semibold",
        paddingLeft: [space["3"], null, null, "initial"],
    },
};
