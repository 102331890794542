import rootRoutes from "@pages/_routes.json";
import bagsAndAccessoriesRoutes from "@pages/bags-and-accessories/_routes.json";
import blackFridayRoutes from "@pages/black-friday/_routes.json";
import campaignsRoutes from "@pages/campaigns/_routes.json";
import checkoutRoutes from "@pages/checkout/_routes.json";
import collectionsRoutes from "@pages/collections/_routes.json";
import customerServiceRoutes from "@pages/customer-service/_routes.json";
import customerServiceLegalRoutes from "@pages/customer-service/legal-pages/_routes.json";
import exploreRoutes from "@pages/explore/_routes.json";
import golfRoutes from "@pages/golf/_routes.json";
import kidsRoutes from "@pages/kids/_routes.json";
import menRoutes from "@pages/men/_routes.json";
import myAccountRoutes from "@pages/my-account/_routes.json";
import myAccountAddressesRoutes from "@pages/my-account/addresses/_routes.json";
import myAccountOrdersRoutes from "@pages/my-account/orders/_routes.json";
import myAccountProfileRoutes from "@pages/my-account/profile/_routes.json";
import myAccountWishlistRoutes from "@pages/my-account/wishlist/_routes.json";
import OrderTrackingRoutes from "@pages/order-tracking/_routes.json";
import orderRoutes from "@pages/order/_routes.json";
import orderConfirmationRoutes from "@pages/order/confirmation/_routes.json";
import outdoorRoutes from "@pages/outdoor/_routes.json";
import productDetailRoutes from "@pages/product/_routes.json";
import saleRoutes from "@pages/sale/_routes.json";
import shoeCareRoutes from "@pages/shoe-care/_routes.json";
import storiesRoutes from "@pages/stories/_routes.json";
import womenRoutes from "@pages/women/_routes.json";

export type PagePath = {
    en: string;
    [key: string]: string;
};

type GetPagePathByLocale = (pagePath: PagePath, locale?: string) => string;

export const getPagePathByLocale: GetPagePathByLocale = (pagePath, locale = "") =>
    pagePath?.[locale] ?? pagePath?.en;

export const getRouteFromRoutesFile = (
    routesObject: Record<string, PagePath>,
    routeName: string,
    additionalPath: PagePath = { en: "" }
): PagePath => {
    const paths = {};

    for (const value of Object?.values(routesObject)) {
        const decodedValue = Object?.fromEntries(
            Object?.entries(value)?.map(([locale, route]) => [
                locale,
                `${additionalPath?.[locale] || ""}/${decodeURIComponent(route)}`,
            ])
        );
        paths[value?.en] = decodedValue;
    }

    return paths?.[routeName];
};

export const homePage: PagePath = {
    en: "/",
};

// Root pages
export const logInPage = getRouteFromRoutesFile(rootRoutes, "log-in");
export const searchPage = getRouteFromRoutesFile(rootRoutes, "search");
export const shoppingBagPage = getRouteFromRoutesFile(rootRoutes, "shopping-bag");
export const storeLocatorPage = getRouteFromRoutesFile(rootRoutes, "store-locator");
export const createAccountPage = getRouteFromRoutesFile(rootRoutes, "create-account");
export const resetPasswordPage = getRouteFromRoutesFile(rootRoutes, "reset-password");

// My account pages
export const myAccountPage = getRouteFromRoutesFile(myAccountRoutes, "my-account");

export const myAccountWishlistPage = getRouteFromRoutesFile(
    myAccountWishlistRoutes,
    "wishlist",
    myAccountPage
);

export const myAccountAddressesPage = getRouteFromRoutesFile(
    myAccountAddressesRoutes,
    "addresses",
    myAccountPage
);

export const myAccountOrdersPage = getRouteFromRoutesFile(
    myAccountOrdersRoutes,
    "orders",
    myAccountPage
);

export const myAccountProfilePage = getRouteFromRoutesFile(
    myAccountProfileRoutes,
    "profile",
    myAccountPage
);

// Campaigns pages
export const campaignsPage = getRouteFromRoutesFile(campaignsRoutes, "campaigns");

// Kids pages
export const kidsCategoryPage = getRouteFromRoutesFile(kidsRoutes, "kids");

// Men pages
export const menCategoryPage = getRouteFromRoutesFile(menRoutes, "men");

// Collections pages
export const collectionsCategoryPage = getRouteFromRoutesFile(collectionsRoutes, "collections");

// Outdoor pages
export const outdoorCategoryPage = getRouteFromRoutesFile(outdoorRoutes, "outdoor");

// Explore pages
export const exploreCategoryPage = getRouteFromRoutesFile(exploreRoutes, "explore");

// Black Friday pages
export const blackFridayPage = getRouteFromRoutesFile(blackFridayRoutes, "black-friday");

// Stories pages
export const storiesPage = getRouteFromRoutesFile(storiesRoutes, "stories");

// Checkout pages
export const checkoutPage = getRouteFromRoutesFile(checkoutRoutes, "checkout");

// Golf pages
export const golfCategoryPage = getRouteFromRoutesFile(golfRoutes, "golf");

// Sale pages
export const saleCategoryPage = getRouteFromRoutesFile(saleRoutes, "sale");

// Product Detail pages
export const productDetailPage = getRouteFromRoutesFile(productDetailRoutes, "product");

// Women pages
export const womenCategoryPage = getRouteFromRoutesFile(womenRoutes, "women");

// Shoe Care pages
export const shoeCareCategoryPage = getRouteFromRoutesFile(shoeCareRoutes, "shoe-care");

// Bags and Accessories pages
export const bagsAndAccessoriesCategoryPage = getRouteFromRoutesFile(
    bagsAndAccessoriesRoutes,
    "bags-and-accessories"
);

// Customer Service pages
export const customerServicePages = getRouteFromRoutesFile(
    customerServiceRoutes,
    "customer-service"
);

export const customerServiceLegalPages = getRouteFromRoutesFile(
    customerServiceLegalRoutes,
    "legal-pages",
    customerServicePages
);

// Order pages
export const orderPage = getRouteFromRoutesFile(orderRoutes, "order");

export const orderConfirmationPage = getRouteFromRoutesFile(
    orderConfirmationRoutes,
    "confirmation",
    orderPage
);

export const orderTrackingPage = getRouteFromRoutesFile(OrderTrackingRoutes, "order-tracking");
