import type {
    PopoverProps as _PopoverProps,
    PopoverContentProps as _PopoverContentProps,
    PopoverHeaderProps as _PopoverHeaderProps,
    PopoverBodyProps as _PopoverBodyProps,
    PopoverFooterProps as _PopoverFooterProps,
    PopoverArrowProps as _PopoverArrowProps,
} from "@chakra-ui/react";
import {
    forwardRef,
    Popover as _Popover,
    PopoverTrigger as _PopoverTrigger,
    PopoverContent as _PopoverContent,
    PopoverHeader as _PopoverHeader,
    PopoverBody as _PopoverBody,
    PopoverFooter as _PopoverFooter,
    PopoverArrow as _PopoverArrow,
    PopoverCloseButton as _PopoverCloseButton,
    PopoverAnchor as _PopoverAnchor,
} from "@chakra-ui/react";

import type { CloseButtonProps } from "../../other/close-button/CloseButton";

export interface PopoverProps extends _PopoverProps {}
export interface PopoverContentProps extends _PopoverContentProps {}
export interface PopoverHeaderProps extends _PopoverHeaderProps {}
export interface PopoverBodyProps extends _PopoverBodyProps {}
export interface PopoverFooterProps extends _PopoverFooterProps {}
export interface PopoverArrowProps extends _PopoverArrowProps {}

export type PopoverCloseButtonProps = CloseButtonProps;

export const Popover: React.FC<PopoverProps> = ({ ...props }) => <_Popover {...props} />;

export const PopoverContent = forwardRef<any, "section">(({ ...props }, ref) => (
    <_PopoverContent {...props} ref={ref} />
));
export const PopoverHeader = forwardRef<PopoverHeaderProps, "header">(({ ...props }, ref) => (
    <_PopoverHeader ref={ref} {...props} />
));
export const PopoverBody = forwardRef<PopoverBodyProps, "div">(({ ...props }, ref) => (
    <_PopoverBody ref={ref} {...props} />
));
export const PopoverFooter = forwardRef<PopoverFooterProps, "footer">(({ ...props }, ref) => (
    //@ts-ignore
    <_PopoverFooter {...props} ref={ref} />
));
export const PopoverArrow = forwardRef<PopoverArrowProps, "div">(({ ...props }, ref) => (
    //@ts-ignore
    <_PopoverArrow {...props} ref={ref} />
));
export const PopoverCloseButton = forwardRef<PopoverCloseButtonProps, "button">(
    ({ ...props }, ref) => (
        //@ts-ignore
        <_PopoverCloseButton {...props} ref={ref} />
    )
);
export const PopoverAnchor = forwardRef<any, "div">(({ ...props }, ref) => (
    <_PopoverAnchor {...props} ref={ref} />
));
export const PopoverTrigger = forwardRef<any, "button">(({ ...props }, ref) => (
    <_PopoverTrigger {...props} ref={ref} />
));
