import { USPbanner } from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import { Container, type ContainerProps } from "@ui/components/layout/container/Container";
import type { FC } from "react";
import dynamic from "next/dynamic";

const Navigation = dynamic(() => import("./navigation/Navigation").then((mod) => mod.Navigation));
const Footer = dynamic(() => import("./footer/Footer").then((mod) => mod.Footer));

export interface LayoutProps extends ContainerProps {
    siteSettings: ISiteSettings;
}

export const Layout: FC<LayoutProps> = ({ siteSettings, children, ...containerProps }) => {
    return (
        <Container px={0} maxW="unset" {...containerProps}>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} />
            )}
            <Navigation navigation={siteSettings?.fields?.mainNavigation} />
            {children}
            <Footer footerData={siteSettings?.fields?.footer} />
        </Container>
    );
};

export default Layout;
