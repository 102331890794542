import type { ILinkProps } from "@components/cms/link/Link";
import { defaultUnderlineStyle } from "@ui/css/common";
import type { TextProps } from "@ui/components/content/text/Text";
import type { InputProps } from "@ui/components/forms/input/Input";
import type { BoxProps } from "@ui/components/layout/box/Box";
import type { ContainerProps } from "@ui/components/layout/container/Container";
import type { FlexProps } from "@ui/components/layout/flex/Flex";
import type { GridProps } from "@ui/components/layout/grid/Grid";
import type { NextLinkProps } from "@ui/components/navigation/link/NextLink";
import { scrollbarStyles } from "@ui/css/01.scrollbar/scrollbar.styles";
import type { MotionStyle } from "framer-motion";
import { sizes, space } from "ui/src/design-tokens";

export const logoContainerStyles: FlexProps["sx"] = {
    zIndex: 1,
    justifyContent: { base: "center", sm: "center", md: "center", lg: "flex-start" },
    px: 4,
    py: 2,
    bg: "white",
    height: sizes.navBarCollapsedHeight,
    backdropFilter: "blur(7.5px)",
    alignItems: "center",
    boxShadow: "right",
};

export const mobileLogoContainerStyles: FlexProps["sx"] = {
    zIndex: 1,
    justifyContent: { base: "center", sm: "center", md: "center", xl: "flex-start" },
    paddingLeft: space["2"],
    paddingRight: space["2"],
    paddingTop: space["2"],
    paddingBottom: space["2"],
    bg: "white",
    w: "100%",
    height: sizes.navBarCollapsedHeight,
    backdropFilter: "blur(7.5px)",
    alignItems: "center",
    boxShadow: "right",
};

export const navContainerStyles: ContainerProps["sx"] = {
    maxW: ["100%", "calc(100vw - 60px)"],
    justifyContent: "center",
    position: "sticky",
    top: [5, 5, 5, 6],
    ml: ["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop", "layoutMargin.desktop"],
    mt: ["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop", "layoutMargin.desktop"],
    mr: ["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop", "layoutMargin.desktop"],
    zIndex: [4, 8],
    w: "auto",
    bg: "transparent",
    padding: [0, 0, 0, 0],
    border: ["none"],
};

// Sub menu list items
export const innerNavLeftGridStyles: GridProps["sx"] = {
    columnGap: 5,
    rowGap: 6,
    h: "fit-content",
    w: "100%",
    bg: "white",
};
export const outerNavLeftStyles: BoxProps["sx"] = {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 0,
    overflowX: "hidden",
    "&:hover": {
        ...scrollbarStyles,
        "::-webkit-scrollbar-track": {
            bg: "gray.200",
        },
    },
    "::-webkit-scrollbar": { width: "scrollbarWidth", height: "scrollbarWidth" },
    "::-webkit-scrollbar-thumb": { background: "transparent" },
    "::-webkit-scrollbar-track": { background: "transparent" },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
};

// Image to the right container
export const innerNavRightStyles: FlexProps["sx"] = {
    position: "sticky",
    maxHeight: sizes.full,
    width: "100%",
    overflow: "auto",
    flexDirection: "column",
    maxW: "100%",
    gap: 10,
    overflowX: "hidden",
    "&:hover": {
        "::-webkit-scrollbar-thumb": { background: "black" },
        "::-webkit-scrollbar-track": { background: "gray.200" },
    },
    "::-webkit-scrollbar": { width: sizes["scrollbarWidth"], height: sizes["scrollbarWidth"] },
    "::-webkit-scrollbar-thumb": { background: "transparent" },
    "::-webkit-scrollbar-track": { background: "transparent" },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    paddingRight: "15px",
};
export const containerStyles: BoxProps["sx"] = {
    display: { base: "flex", sm: "flex", md: "flex", lg: "flex", xl: "flex" },
    color: "black",
    gap: space["0.2"],
    px: [0],
    alignItems: "center",
    bg: "transparent",
    position: "relative",
    justifyContent: { base: "flex-start" },
    gridTemplateColumns: "1fr 1fr 1fr",
    boxShadow: "navigation",
    w: ["auto", "auto", "auto", "auto", "fit-content"],
};

export const menuContainerStyles: ContainerProps["sx"] = {
    display: "flex",
    justifyContent: "space-between",
    gap: 3,
    height: `calc(${sizes.navExpandedHeight} - ${sizes.navBarCollapsedHeight})`,
    paddingLeft: "30px",
    paddingRight: "15px",
    paddingTop: "30px",
    paddingBottom: "30px",
};
export const rightContainerStyles: FlexProps["sx"] = {
    gap: 1,
    alignItems: "center",
    backdropFilter: "blur(7.5px)",
};
export const navItemContainerStyles: FlexProps["sx"] = {
    px: 1,
    background: "linear-gradient(black 0 0) 12px 99% /var(--d, 0) 1.5px no-repeat",
    transition: "cubic-bezier(0.85, 0, 0.15, 1)0.5s",
    _hover: {
        "--d": "calc(100% - 24px)",
    },
    height: sizes.navBarCollapsedHeight,
    bg: "white",
    mr: -0.2,
};

// Sub-Menu Headings Titles
export const smallHeadingStyles: TextProps["sx"] = {
    fontWeight: "bold",
    color: "black",
    textTransform: "uppercase",
    fontSize: "desktopBodyTextNormal",
    marginBottom: 4,
};

export const navItemStyles: NextLinkProps["sx"] = {
    fontSize: "md",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    color: "black !important",
    letterSpacing: "0.2px",
    px: 2,
    textDecoration: "none",
    _hover: {
        ...defaultUnderlineStyle,
    },
};
export const linkItemStyles: ILinkProps["sx"] = {
    textDecoration: "none",
    fontSize: "desktopBodyTextNormal",
    fontWeight: "semibold",
    color: "black",
    whiteSpace: "wrap",
    lineHeight: "shorter",
    background: "linear-gradient(currentColor 0 0) 0 100% /var(--b, 0) 1px no-repeat",
    transition: "0.5s",
    paddingBottom: 1,
    maxW: "225px",
    _hover: {
        "--b": "100%",
    },
    _active: {
        textDecoration: "none",
    },
};

export const titleLinkStyles: ILinkProps["sx"] = {
    ...linkItemStyles,
    textTransform: "uppercase",
    marginBottom: 4,
};

export const animatedContainerStyles: BoxProps["sx"] = {
    overflow: "hidden",
    background: "transparent",
    whiteSpace: "nowrap",
    position: "absolute",
    right: "0",
    left: ["initial", "initial", "initial", "-130px", "-130px"],
    top: "53px",
    height: "auto",
    maxH: "80vh",
    overscrollBehavior: "contain",
    w: ["100%", "100%", "calc(100vw - 60px)"],
};

export const animatedContainerMobileStyles: MotionStyle = {
    whiteSpace: "nowrap",
    position: "absolute",
    right: "0",
    left: "0",
    height: "auto",
    top: "53px",
    maxHeight: "calc(100dvh - 52px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "auto",
    zIndex: 44,
    gap: 32,
};

export const categoryTitleMobileStyles: TextProps["sx"] = {
    fontWeight: "semibold",
    color: "black",
    textDecoration: "none",
    fontSize: "desktopHeading5",
};

export const mobileMenuContainerStyles: FlexProps["sx"] = {
    display: ["flex", "flex", "flex", "flex", "none"],
    pt: space["2"],
    flexDirection: "column",
    position: "relative",
    inset: "auto 0",
};

export const navItemMobileStyles: FlexProps["sx"] = {
    justifyContent: "space-between",
    alignItems: "center",
    w: "100%",
    alignContent: "center",
    textDecoration: "none",
    bg: "transparent",
    pb: space["4"],
    px: 4,
    _active: { bg: "transparent" },
    _hover: { bg: "transparent" },
};

export const linksContainerStyles: FlexProps["sx"] = {
    justifyContent: "flex-start",
    backdropFilter: "blur(7.5px)",
    gap: space["0.2"],
    backgroundColor: "transparent",
    position: "relative",
    display: { base: "none", md: "none", lg: "flex" },
};

export const bottomContainerLinkStyles: NextLinkProps["sx"] = {
    display: "flex",
    fontWeight: "semibold",
    fontSize: "desktopBodyTextNormal",
    gap: sizes[2],
    textDecoration: "none",
    color: "black",
    alignItems: "center",
    "& svg": {
        translate: "0",
        transition: "translate .3s ease-in-out",
    },
    _hover: {
        color: "black",
        "& svg": {
            translate: space["1"],
        },
        ...defaultUnderlineStyle,
    },
    _focus: {
        color: "black",
        "& svg": {
            translate: space["1"],
        },
        ...defaultUnderlineStyle,
    },
    _active: {
        textDecoration: "none",
    },
};

export const bottomContainerMobileStyles: FlexProps["sx"] = {
    px: 4,
    flexDirection: "column",
    bg: "white",
    py: 4,
    gap: 4,
};

// Second Sub-Menu
export const mobileButtonBackStyles: FlexProps["sx"] = {
    w: "100%",
    bg: "white",
    justifyContent: "flex-start",
    alignContent: "center",
    fontWeight: "semibold",
    color: "black",
    gap: 2,
    fontSize: "desktopHeading5",
    _hover: {
        "--b": "100%",
        textDecoration: "none",
        color: "black",
    },
    _active: {
        textDecoration: "none",
        color: "black",
    },
};

//Second submenu back button style adjust pb and pt
export const seeAllCategoriesMobileStyles: FlexProps["sx"] = {
    fontWeight: "semibold",
    color: "black",
    textDecoration: "none",
    fontSize: "desktopHeading5",
    pb: space["3"],
    pt: 5,
    px: 4,
    textTransform: "uppercase",
    alignItems: "center",
    justifyContent: "space-between",
    _hover: {
        borderColor: "transparent",
        outline: "none",
    },
    _focus: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _focusVisible: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _active: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
};

export const parentThumbnailContainerStyles: BoxProps["sx"] = {
    position: "relative",
    height: "auto",
    my: 6,
};

export const thumbnailCarouselContainerStyles: FlexProps["sx"] = {
    display: "flex",
    maxWidth: "100vw",
    flexWrap: "nowrap",
    overflowY: "auto",
    pb: 2,
    ...scrollbarStyles,
    left: 0,
    /* We are using negative value to avoid cut the scroll area */
    px: 4,
    "::-webkit-scrollbar-track": {
        background: "gray.200",
        marginRight: 4,
        marginLeft: 4,
    },
};

export const thumbnailCarouselItemStyles: FlexProps["sx"] = {
    height: "100%",
    gap: 2,
    flexWrap: "nowrap",
    width: "auto",
};

export const mobileLinkStyles: ILinkProps["sx"] = {
    ...categoryTitleMobileStyles,
    textTransform: "uppercase",
    py: space["3"],
    px: 4,
    _hover: {
        borderColor: "transparent",
        outline: "none",
    },
    _focus: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _focusVisible: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _active: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
};

//Search input - reuse the input field for the mobile design
export const mobileSearchInputStyles: InputProps["sx"] = {
    color: "black",
    borderRadius: "none",
    transform: "scale(1)",
    marginRight: 0,
    px: 10,
    py: 3,
    height: [sizes.navBarCollapsedHeight],
    zIndex: 2,
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    borderColor: "transparent",
    fontSize: "desktopBodyTextSmall",
    _placeholder: {
        color: "black",
        fontSize: " desktopBodyTextSmall",
        whiteSpace: "nowrap",
        textOverflow: "elipsis",
    },
    _hover: {
        borderColor: "transparent",
        outline: "none",
    },
    _focus: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _focusVisible: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
    _active: {
        outlineColor: "transparent",
        outline: "none",
        border: "none",
    },
};

export const desktopNavWrapperStyles: FlexProps["sx"] = {
    gap: 0.2,
    display: { base: "none", xl: "flex" },
};

export const iconWrapperStyles: FlexProps["sx"] = {
    display: { base: "flex", xl: "none" },
    gap: "0.2",
    w: "fit-content",
};

export const searchIconWrapperStyles: FlexProps["sx"] = {
    boxShadow: "right",
};

export const toggleNavigationButtonStyles: (searchBarOpen: boolean) => FlexProps["sx"] = (
    searchBarOpen
) => ({
    mr: "0.2",
    backdropFilter: "blur(7.5px)",
    boxShadow: "right",
    "& svg": {
        color: searchBarOpen ? "white" : "initial",
        transition: "color .4s ease",
    },
});

export const desktopNavItemWrapperStyles: FlexProps["sx"] = {
    boxShadow: "right",
};
