import type { CheckboxProps as _CheckboxProps } from "@chakra-ui/react";
import { forwardRef, Checkbox as _Checkbox } from "@chakra-ui/react";
import { CheckboxIcon } from "./CheckboxIcon";
import { useEffect } from "react";
import { noHoverOnTouchDevice } from "../../../css/common";

export interface CheckboxProps extends _CheckboxProps {}

// Hack to get around the fact that Chakra's Checkbox prior to version ^2 have a bug where it...
// ...focus the checkbox when it is clicked. We only want to focus when tabing with the keyboard.
const removeDataAttr = (attr: string) => {
    requestAnimationFrame(() => {
        document.querySelector(`.chakra-checkbox__control[${attr}]`)?.removeAttribute(attr);
    });
};

const dataFocus = "data-focus";
const dataHover = "data-hover";

export const Checkbox = forwardRef<CheckboxProps, "input">(
    ({ icon = <CheckboxIcon />, onMouseDown, onMouseUp, ...props }, ref) => {
        useEffect(() => {
            const handleFocus = () => removeDataAttr(dataFocus);
            const handleHover = () => removeDataAttr(dataHover);

            window.addEventListener("focus", handleFocus);
            window.addEventListener("mouseover", handleHover);

            return () => {
                window.removeEventListener("focus", handleFocus);
                window.removeEventListener("mouseover", handleHover);
            };
        }, []);

        return (
            <_Checkbox
                ref={ref}
                {...props}
                icon={icon}
                onMouseDown={(e) => {
                    removeDataAttr(dataFocus);
                    removeDataAttr(dataHover);
                    onMouseDown?.(e);
                }}
                onMouseUp={(e) => {
                    removeDataAttr(dataFocus);
                    removeDataAttr(dataHover);
                    onMouseUp?.(e);
                }}
                sx={{
                    [noHoverOnTouchDevice]: {
                        _hover: {
                            ".chakra-checkbox__control": {
                                borderColor: ["gray.100", "inherit"],
                            },
                            _disabled: {
                                pointerEvents: "none",
                            },
                        },
                    },
                }}
                _disabled={{
                    ".chakra-checkbox__control": {
                        background: "transparent",
                    },
                    "chakra-checkbox__label": {
                        color: "gray.700",
                    },
                    pointerEvents: "none",
                }}
            />
        );
    }
);
