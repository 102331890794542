import { CheckOutLayout } from "@components/layouts/CheckOutLayout";
import { CustomerServiceLayout } from "@components/layouts/CustomerServiceLayout";
import { Layout } from "@components/layouts/Layout";
import { MyAccountLayout } from "@components/layouts/MyAccountLayout";
import { OrderConfirmationLayout } from "@components/layouts/OrderConfirmationLayout";
import { OrderTrackingLayout } from "@components/layouts/OrderTrackingLayout";
import { ProductLayout } from "@components/layouts/ProductLayout";
import { ProductListLayout } from "@components/layouts/ProductListLayout";
import type { GetLayout } from "@interfaces/PageInterfaces";
import dynamic from "next/dynamic";

const BackToTop = dynamic(() => import("@components/ui/backToTop/BackToTop"), { ssr: false });

export const getDefaultLayout: GetLayout = ({ page, siteSettings }) => {
    return (
        <Layout siteSettings={siteSettings}>
            {page}
            <BackToTop />
        </Layout>
    );
};

export const getDefaultLayoutWithGrayBody: GetLayout = ({ page, siteSettings }) => {
    return (
        <Layout siteSettings={siteSettings} backgroundColor="gray.50">
            {page}
            <BackToTop />
        </Layout>
    );
};

export const getDefaultLayoutWithGrayBodyNoBackToTop: GetLayout = ({ page, siteSettings }) => {
    return (
        <Layout siteSettings={siteSettings} backgroundColor="gray.50">
            {page}
        </Layout>
    );
};

export const getProductLayout: GetLayout = ({ page, siteSettings }) => (
    <ProductLayout siteSettings={siteSettings}>{page}</ProductLayout>
);

export const getProductListLayout: GetLayout = ({ page, siteSettings }) => (
    <ProductListLayout siteSettings={siteSettings}>
        {page}
        <BackToTop />
    </ProductListLayout>
);

export const getCheckOutLayout: GetLayout = ({ page, siteSettings }) => (
    <CheckOutLayout siteSettings={siteSettings}>{page}</CheckOutLayout>
);

export const getOrderConfirmationLayout: GetLayout = ({ page, siteSettings }) => (
    <OrderConfirmationLayout siteSettings={siteSettings}>
        {page}
        <BackToTop />
    </OrderConfirmationLayout>
);

export const getCustomerServiceLayout: GetLayout = ({ page, siteSettings }) => (
    <CustomerServiceLayout siteSettings={siteSettings}>
        {page}
        <BackToTop chatButtonPersist={true} />
    </CustomerServiceLayout>
);

export const getMyAccountLayout: GetLayout = ({ page, siteSettings, pageProps }) => {
    return (
        <MyAccountLayout pageProps={pageProps} siteSettings={siteSettings}>
            {page}
            <BackToTop />
        </MyAccountLayout>
    );
};

export const getOrderTrackingLayout: GetLayout = ({ page, siteSettings }) => {
    return (
        <OrderTrackingLayout siteSettings={siteSettings}>
            {page}
            <BackToTop />
        </OrderTrackingLayout>
    );
};
