import { USPbanner } from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { ContainerProps } from "@ui/components/layout/container/Container";
import { Container } from "@ui/components/layout/container/Container";
import type { FC } from "react";
import dynamic from "next/dynamic";

const Navigation = dynamic(() => import("./navigation/Navigation").then((mod) => mod.Navigation));
const Footer = dynamic(() => import("./footer/Footer").then((mod) => mod.Footer));

export interface OrderConfirmationLayoutProps extends ContainerProps {
    siteSettings: ISiteSettings;
}

export const OrderConfirmationLayout: FC<OrderConfirmationLayoutProps> = ({
    siteSettings,
    children,
    ...rest
}) => {
    return (
        <>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} />
            )}
            <Navigation navigation={siteSettings?.fields?.mainNavigation} />
            <Container py={0} px={0} maxW="100%" bg="gray.50" {...rest}>
                {children}
            </Container>
            <Footer footerData={siteSettings?.fields?.footer} />
        </>
    );
};

export default OrderConfirmationLayout;
