import type { IModuleHotspot } from "@contentful-api/types/contentful";
import { contentfulLoader } from "@delicious-simplicity/next-image-contentful-loader";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { Text } from "@ui/components/content/text/Text";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import {
    contentfulLoaderDefaultProps,
    protocolRelativeToAbsoluteUrl,
} from "@ui/components/media-and-icons/image/NextImage";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { useMemo } from "react";
import type { Marker } from "react-image-marker";
import ImageMarker from "react-image-marker";
import type { CustomMarkerProps } from "./CustomMarker";
import { CustomMarker } from "./CustomMarker";
import { CustomMarkerProduct } from "./CustomMarkerProduct";

interface IHotspotProps extends IModuleRenderedProps<IModuleHotspot> {
    backgroundColor?: string;
}

const CreateImageMarker = (image: IModuleHotspot["fields"]["imageDesktop"], hotspots: Marker[]) => {
    return (
        <ImageMarker
            src={contentfulLoader(
                {
                    src: protocolRelativeToAbsoluteUrl(image?.fields?.file.url),
                    width: image?.fields?.file.details.image.width,
                },
                contentfulLoaderDefaultProps
            )}
            markers={hotspots}
            markerComponent={(props: CustomMarkerProps) =>
                props.product
                    ? CustomMarkerProduct({ ...props })
                    : CustomMarker({
                          ...props,
                      })
            }
            bufferLeft={16}
            bufferTop={16}
        />
    );
};

export const Hotspot: FC<IHotspotProps> = ({ module, backgroundColor }) => {
    const {
        title,
        description,
        hotspotsDesktop,
        hotspotMobile,
        gradient,
        imageDesktop,
        imageMobile,
    } = module.fields;

    const parsedHotspotsDesktop = useMemo(
        () =>
            hotspotsDesktop?.map((hotspot) => ({
                left: hotspot.left,
                top: hotspot.top,
                markerNumber: hotspot.markerNumber,
                product: hotspot?.hotspot?.fields?.product,
                title: hotspot?.hotspot?.fields?.title,
                description: hotspot?.hotspot?.fields?.description,
            })),
        [hotspotsDesktop]
    );

    const parsedHotspotsMobile = useMemo(
        () =>
            hotspotMobile?.map((hotspot) => ({
                left: hotspot.left,
                top: hotspot.top,
                markerNumber: hotspot.markerNumber,
                product: hotspot?.hotspot?.fields?.product,
                title: hotspot?.hotspot?.fields?.title,
                description: hotspot?.hotspot?.fields?.description,
            })),
        [hotspotMobile]
    );

    //If there is no image or the image is empty, we don't render the component
    if (isEmpty(imageDesktop) && isEmpty(imageMobile)) return null;

    return (
        <Box
            mt={6}
            position="relative"
            data-contentful-entry-id={module?.sys?.id}
            data-contentful-field-id={"moduleHotspot"}
        >
            <Flex
                width={["100%", "100%", "100%"]}
                height={["500px", "100%", "100%"]}
                position="relative"
                flexDirection="column"
                justifyContent="flex-end"
                /* If there is a product image, the aspect ratio will be 1/1.
                We need this fixed values to give the container a specific height
                and mantain the image in the center with the reference to the x and y axis for the hotspot */
                display="flex"
                alignItems={["center", "flex-start"]}
                backgroundColor={backgroundColor}
                sx={{
                    "& .image-marker": {
                        width: "100%",
                        height: "100%",
                    },
                    "& img": {
                        height: "100%",
                    },
                }}
            >
                {/* We cannot handle responsiveness with the current library, so we will just hide/show both components
                    Is not possible use values from the hooks like md, lg from useThemMediaQuery to render the component conditionally
                    because the hooks will run only when React renders, causing layout shifts, afecting lighthouse scores.
                */}
                <Box display={["none", "none", "none", "flex"]} w="full">
                    {CreateImageMarker(imageDesktop, parsedHotspotsDesktop)}
                </Box>
                <Box display={["flex", "flex", "flex", "none"]} h="100%" w="full">
                    {CreateImageMarker(imageMobile, parsedHotspotsMobile)}
                </Box>
                {(title || description) && (
                    <>
                        {gradient && (
                            <Flex
                                sx={{
                                    position: "absolute",
                                    height: "100%",
                                    top: "0",
                                    flexDirection: "column",
                                    justifyContent: "end",
                                    width: "100%",
                                    zIndex: 0,
                                    backgroundImage: [
                                        "linear-gradient(to bottom, rgba(0, 0, 0, .42) 25%, rgba(0, 0, 0, 0.02) 48.44%, rgba(0, 0, 0, 0) 100%)",
                                        "linear-gradient(to bottom, rgba(0, 0, 0, .42) 25%, rgba(0, 0, 0, 0.02) 48.44%, rgba(0, 0, 0, 0) 100%)",
                                        "linear-gradient(rgba(0, 0, 0, 0.42) 10%, rgba(0, 0, 0, 0.02) 30%, rgba(0, 0, 0, 0) 50%)",
                                    ],
                                }}
                            ></Flex>
                        )}
                        <Flex
                            top={[4, 6]}
                            position={"absolute"}
                            direction={"column"}
                            w={["100%", "auto"]}
                            textAlign={"start"}
                            zIndex={1}
                            px={[4, 6]}
                            color={gradient ? "white" : "black"}
                        >
                            {title && (
                                <Text
                                    fontSize={[
                                        "mobileHeading1",
                                        "desktopHeading2",
                                        "desktopHeading2",
                                    ]}
                                    fontWeight="bold"
                                >
                                    {title}
                                </Text>
                            )}
                            {description && (
                                <Text
                                    fontSize={["mobileBodyTextNormal", "desktopBodyTextNormal"]}
                                    fontWeight="bold"
                                    mt={[2, 3]}
                                >
                                    {description}
                                </Text>
                            )}
                        </Flex>
                    </>
                )}
            </Flex>
        </Box>
    );
};
