import type { IconProps } from "@chakra-ui/react";
import { forwardRef, Icon, useStyleConfig } from "@chakra-ui/react";

export interface ECCOLogoProps extends IconProps {}

export const ECCOLogo = forwardRef<ECCOLogoProps, "svg">(({ ...props }, ref) => {
    const {
        as,
        viewBox,
        color = "currentColor",
        focusable = false,
        // no need for these props
        children,
        className,
        css,
        __css,
        ...rest
    } = props;

    const styles = {
        ...useStyleConfig("ECCOLogo"),
        color,
    };

    return (
        <Icon
            aria-hidden="true"
            viewBox={"0 0 110 30"}
            __css={styles}
            focusable={focusable}
            ref={ref}
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.334 4.22886C37.7832 3.68403 39.2579 3.41943 40.905 3.41943C45.0487 3.41943 48.7544 5.31119 51.1741 8.27391C51.4925 8.66413 51.7024 9.17157 51.7024 9.70754C51.7024 10.4549 51.3758 11.0693 50.8138 11.4797L50.7881 11.5275C50.4097 11.8047 49.9334 11.9677 49.4262 11.9677C48.7092 11.9677 48.0674 11.6418 47.6491 11.1268C47.1569 10.5222 46.5964 10.0027 45.959 9.54605C44.5377 8.53631 42.7876 7.93977 40.905 7.93977C39.8245 7.93977 38.8546 8.12149 37.9028 8.48099C36.0978 9.15659 34.5319 10.4235 33.5274 12.0298C32.7117 13.3444 32.2302 14.8931 32.2302 16.545C32.2302 17.7595 32.4988 18.9112 32.9518 19.9562C33.5574 21.3554 34.513 22.5646 35.7261 23.4646C37.1708 24.5378 38.9593 25.1709 40.905 25.1709C43.6146 25.1709 46.0667 23.8869 47.6551 21.9526C48.0741 21.4256 48.7138 21.093 49.4353 21.093C50.6934 21.093 51.7174 22.1065 51.7174 23.3562C51.7174 23.8936 51.5286 24.3884 51.2088 24.7785C48.7844 27.7795 45.081 29.6929 40.905 29.6929C33.6027 29.6929 27.6787 23.8106 27.6787 16.545C27.6787 13.9865 28.4259 11.5925 29.7058 9.56616C31.2302 7.15954 33.6176 5.24832 36.334 4.22886Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.7027 4.24081C65.1557 3.69675 66.6289 3.4292 68.276 3.4292C72.4218 3.4292 76.1254 5.32238 78.5497 8.28968C78.8628 8.67827 79.071 9.17904 79.071 9.72244C79.071 10.4646 78.7453 11.0783 78.1871 11.4946L78.1607 11.5394C77.7807 11.8175 77.3043 11.9805 76.795 11.9805C76.0757 11.9805 75.4428 11.6516 75.026 11.1358C74.5347 10.5349 73.9673 10.0102 73.3345 9.56325C71.911 8.54597 70.1631 7.95107 68.276 7.95107C67.1977 7.95107 66.2295 8.14088 65.2783 8.4959C63.4747 9.1716 61.9029 10.4355 60.9007 12.0448C60.0866 13.3565 59.6013 14.9058 59.6013 16.5719C59.6013 17.7767 59.8767 18.924 60.3296 19.9674C60.9324 21.3681 61.8863 22.5752 63.1016 23.4736C64.544 24.5506 66.3393 25.1814 68.276 25.1814C70.9915 25.1814 73.4377 23.9019 75.0305 21.9616C75.4488 21.4384 76.0907 21.102 76.81 21.102C78.0772 21.102 79.0906 22.1147 79.0906 23.3667C79.0906 23.9041 78.8995 24.4034 78.5835 24.7875C76.1576 27.7877 72.4475 29.7056 68.276 29.7056C60.9736 29.7056 55.0498 23.8234 55.0498 16.5719C55.0498 13.997 55.797 11.6007 57.0784 9.57823C58.6072 7.16777 60.9887 5.25732 63.7027 4.24081Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.3993 24.7785C87.8229 27.7795 91.5271 29.6929 95.709 29.6929C103.009 29.6929 108.927 23.8106 108.927 16.545C108.927 13.9865 108.19 11.5925 106.91 9.56616C106.505 8.93168 105.788 8.49072 104.97 8.49072C103.72 8.49072 102.698 9.50494 102.698 10.7547C102.698 11.1978 102.861 11.68 103.081 12.0298C103.904 13.3444 104.377 14.8931 104.377 16.545C104.377 17.7595 104.108 18.9112 103.653 19.9562C103.044 21.3554 102.09 22.5646 100.888 23.4646C99.432 24.5378 97.6436 25.1709 95.709 25.1709C92.9912 25.1709 90.5392 23.8869 88.9545 21.9526C87.7326 20.4682 87.0269 18.6168 87.0269 16.545C87.0269 13.6681 88.4541 11.1134 90.6467 9.54605C92.0636 8.53631 93.8174 7.93977 95.709 7.93977C96.7784 7.93977 97.7616 8.12149 98.7112 8.48099C98.9534 8.56693 99.2198 8.61405 99.4862 8.61405C100.744 8.61405 101.764 7.60203 101.764 6.3523C101.764 5.37985 101.144 4.54648 100.274 4.22886C98.8294 3.68403 97.3477 3.41943 95.709 3.41943C91.5571 3.41943 87.8506 5.31119 85.4227 8.27391C83.5784 10.5349 82.4746 13.4147 82.4746 16.545C82.4746 19.6639 83.5784 22.5266 85.3993 24.7785Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00568 4.22886C8.14042 4.54878 7.51963 5.38062 7.51963 6.35831C7.51963 7.6087 8.53766 8.62149 9.79576 8.62149C10.0681 8.62149 10.3322 8.57218 10.5708 8.48318C11.5241 8.12663 12.5029 7.9436 13.5827 7.9436C15.4593 7.9436 17.2312 8.57589 18.6548 9.59011L5.61972 19.9607C5.16833 18.9157 4.90867 17.7663 4.90867 16.5681C4.90867 14.9006 5.379 13.3481 6.19681 12.035C6.42037 11.6845 6.5858 11.2023 6.5858 10.7561C6.5858 9.50647 5.57008 8.49816 4.30989 8.49816C3.49791 8.49816 2.78092 8.9354 2.37983 9.56846C1.09763 11.5925 0.350586 13.988 0.350586 16.5681C0.350586 23.8144 6.2743 29.6966 13.5827 29.6966C17.7527 29.6966 21.4651 27.7817 23.8887 24.7785C24.2069 24.3907 24.3913 23.8981 24.3913 23.3584C24.3913 22.1065 23.3695 21.0974 22.113 21.0974C21.3944 21.0974 20.7458 21.4256 20.3335 21.9547C18.7421 23.8913 16.2906 25.1732 13.5827 25.1732C11.6399 25.1732 9.85134 24.5289 8.40668 23.4609L23.4749 11.4893C24.0347 11.07 24.3807 10.4579 24.3807 9.71268C24.3807 9.17605 24.1679 8.66555 23.8526 8.28211C21.4305 5.31338 17.7248 3.41943 13.5827 3.41943C11.9364 3.41943 10.4594 3.68545 9.00568 4.22886Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M104.441 3.12969C104.441 1.88007 105.468 0.862793 106.726 0.862793C107.984 0.862793 109.008 1.88007 109.008 3.12969C109.008 4.38094 107.984 5.39745 106.726 5.39745C105.468 5.39745 104.441 4.38094 104.441 3.12969ZM103.896 3.12957C103.896 4.67899 105.165 5.93845 106.726 5.93845C108.286 5.93845 109.553 4.67899 109.553 3.12957C109.553 1.58168 108.286 0.320801 106.726 0.320801C105.165 0.320801 103.896 1.58168 103.896 3.12957Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.82 1.7393H106.423C107.024 1.7393 107.741 1.71984 107.741 2.49187C107.741 2.82229 107.52 3.09356 107.161 3.14068V3.14735C107.322 3.15938 107.404 3.31179 107.46 3.43436L107.909 4.52117H107.284L106.945 3.65194C106.87 3.44562 106.796 3.3657 106.558 3.3657H106.377V4.52117H105.82V1.7393ZM106.377 2.9312H106.563C106.837 2.9312 107.157 2.88932 107.157 2.54109C107.157 2.204 106.842 2.1748 106.563 2.1748H106.377V2.9312Z"
                fill="currentColor"
            />
        </Icon>
    );
});
