import type { FC } from "react";
import { Text } from "@ui/components/content/text/Text";
import { Grid } from "@ui/components/layout/grid/Grid";
import type { ILink } from "@contentful-api/types/contentful";
import { Link } from "@components/cms/link/Link";
import { NextImage } from "@ui/components/media-and-icons/image/NextImage";

interface CategoryTileProps {
    link: ILink;
    imageSizes: string[];
    isLeftSide?: boolean;
}

export const CategoryTile: FC<CategoryTileProps> = ({ link, imageSizes, isLeftSide = false }) => {
    return (
        <Link
            sx={{
                whiteSpace: "break-spaces",
                textDecoration: "none",
                _hover: { textDecoration: "none" },
                minWidth: "250px",
            }}
            link={link}
            w="full"
        >
            <Grid gridTemplateRows={"1fr auto"} alignItems={"end"}>
                <NextImage
                    src={link?.fields?.linkImage?.fields?.file?.url}
                    alt={link?.fields?.linkImage?.fields?.description ?? link?.fields?.label}
                    layout={"fill"}
                    objectFit={"cover"}
                    sizes={imageSizes}
                    sx={{
                        isolation: "isolate",
                        objectFit: "cover",
                        aspectRatio: "3/2",
                        "& img": {
                            transition: "transform .45s ease-in-out",
                        },
                        "@media (hover: hover) and (pointer: fine) ": {
                            _hover: {
                                "& img": {
                                    transform: "scale(1.025)",
                                },
                            },
                        },
                    }}
                />
                <Text
                    sx={{
                        mt: 2,
                        fontSize: isLeftSide
                            ? ["mobileLinkL1", "desktopBodyB3"]
                            : ["mobileLinkL1", "mobileLinkL1", "desktopH2", "desktopH2"],
                        color: "black",
                        fontWeight: "bold",
                    }}
                >
                    {link?.fields?.label}
                </Text>
                {link?.fields?.description && (
                    <Text mt={2} fontWeight="bold">
                        {link?.fields?.description}
                    </Text>
                )}
            </Grid>
        </Link>
    );
};
