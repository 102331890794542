import { animatedContainerStyles } from "@components/layouts/navigation/styles";
import { Box } from "@ui/components/layout/box/Box";

export const AnimatedWrapper = ({
    height,
    isChangingRoute,
}: {
    height: number;
    isChangingRoute: boolean;
}) => {
    return (
        <Box
            sx={{
                ...animatedContainerStyles,
                backgroundColor: "white",
                height: height,
                zIndex: -1,
                transition: !isChangingRoute
                    ? "height .55s cubic-bezier(0.85, 0, 0.15, 1)"
                    : "none",
            }}
        ></Box>
    );
};
