import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { IModuleUspBanner } from "@contentful-api/types/contentful";
import type { Document } from "@contentful/rich-text-types";
import { removeEmptyContent } from "@lib/utils/cleanContent";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { VisuallyHidden } from "@ui/components/other/visually-hidden/VisuallyHidden";
import { useBreakpointValue } from "@ui/hooks/useBreakpointValue";
import { forwardRef } from "@ui/utils/theme-tools";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { styles } from "./USPbanner.styles";

interface IUSPBannerProps {
    uspBanner: IModuleUspBanner;
    bannerBackground?: string;
}

const BANNER_DELAY = 5000;

export const USPbanner = forwardRef(({ uspBanner, bannerBackground }: IUSPBannerProps, ref) => {
    const [index, setIndex] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [isMultiline, setIsMultiline] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isMobile = useBreakpointValue({ base: true, md: false });

    const uspMessages = Object.values(uspBanner?.fields || {})
        .filter(Boolean)
        .map((item) => removeEmptyContent(item))
        .filter((item) => !isEmpty(item.content));

    const checkMultiline = (fieldMessage: Document) => {
        const textContent = fieldMessage?.content?.[0]?.content?.[0];

        if (textContent?.nodeType === "text") {
            setIsMultiline(textContent.value.length > (isMobile ? 50 : 120));
        }
    };

    useEffect(() => {
        if (isRunning && uspMessages.length > 1) {
            timeoutRef.current = setInterval(() => {
                setIndex((prevIndex) => (prevIndex + 1) % uspMessages.length);
            }, BANNER_DELAY);
        }

        checkMultiline(uspMessages[index]);

        return () => clearInterval(timeoutRef.current!);
    }, [index, isMobile, isRunning, uspMessages.length]);

    return (
        <Box ref={ref} w="100%" shadow={"base"} px={0}>
            <VisuallyHidden>
                {uspMessages.map((item, i) => (
                    <Box key={`uspMessage${i}`}>{renderRichTextToComponent(item)}</Box>
                ))}
            </VisuallyHidden>
            <Flex
                sx={{
                    ...styles.container,
                    height: isMultiline ? "100%" : "6",
                    justifyContent: uspMessages.length > 1 ? "space-between" : "space-around",
                    backgroundColor: bannerBackground ?? "gray.100",
                }}
                data-testid="USPBanner"
            >
                {uspMessages.length > 1 && (
                    <IconButton
                        visibility={["visible"]}
                        transform={"rotate(90deg)"}
                        aria-label="Left Arrow"
                        variant="tertiary"
                        colorScheme="transparent"
                        bg="initial"
                        sx={{
                            _hover: {
                                background: "initial !important",
                            },
                        }}
                        icon={<ECCOIcon name="chevron" color="black" />}
                        size="sm"
                        onClick={() => {
                            setIndex((prevIndex: number) =>
                                prevIndex === 0 ? uspMessages.length - 1 : prevIndex - 1
                            );
                            setIsRunning(false);
                        }}
                    />
                )}
                <AnimatePresence>
                    <Box sx={styles.animatedContainer}>
                        {uspMessages.map(
                            (item, i) =>
                                i === index && (
                                    <motion.div
                                        key={i}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {renderRichTextToComponent(item)}
                                    </motion.div>
                                )
                        )}
                    </Box>
                </AnimatePresence>
                {uspMessages.length > 1 && (
                    <IconButton
                        visibility={["visible"]}
                        transform={"rotate(-90deg)"}
                        aria-label="Right Arrow"
                        variant="tertiary"
                        colorScheme="transparent"
                        bg="transparent"
                        icon={<ECCOIcon name="chevron" color="black" />}
                        size="sm"
                        sx={{
                            _hover: {
                                background: "initial !important",
                            },
                        }}
                        onClick={() => {
                            setIndex((prevIndex: number) =>
                                prevIndex === uspMessages.length - 1 ? 0 : prevIndex + 1
                            );
                            setIsRunning(false);
                        }}
                    />
                )}
            </Flex>
        </Box>
    );
});

export default USPbanner;
