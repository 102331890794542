import USPbanner from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { PropsWithChildren, ReactNode } from "react";
import { Footer } from "./footer/Footer";
import { Navigation } from "./navigation/Navigation";

export interface ProductLayoutProps {
    siteSettings: ISiteSettings;
    children?: ReactNode;
}

export const ProductLayout = ({
    siteSettings,
    children,
}: PropsWithChildren<ProductLayoutProps>) => {
    return (
        <>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} bannerBackground={"white"} />
            )}
            <Navigation isProductPage navigation={siteSettings?.fields?.mainNavigation} />

            {children}
            <Footer footerData={siteSettings?.fields?.footer} />
        </>
    );
};

export default ProductLayout;
