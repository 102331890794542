import { styles } from "./styles";
import LogoutButton from "../LogOutButton";
import { useAuth } from "@lib/auth/useAuth";
import { Box } from "@ui/components/layout/box/Box";
import { CommonCMS } from "@lib/constants/contentful";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Text } from "@ui/components/content/text/Text";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { createAccountPage, getPagePathByLocale, logInPage } from "@lib/constants/pagePaths";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { Button } from "@ui/components/forms/button/Button";
import { useRouter } from "next/router";

function AuthenticationActionBar(): JSX.Element {
    const { isAuthenticated, session } = useAuth();
    const { get: getMicrocopy } = useMicrocopy();
    const { locale } = useRouter();

    if (isAuthenticated) {
        const loggedInAs = getMicrocopy(CommonCMS.global, CommonCMS.loggedInAs, {
            replacements: {
                name: session?.user?.name,
            },
        })?.split(":");

        return (
            <Box w="full" data-testid={"authentication"}>
                {session?.user && (
                    <Flex color="gray.800" mb="0.5em">
                        <Text>{loggedInAs?.[0]}</Text>
                        <Text data-privacy="true" color="black" pl={1}>
                            {loggedInAs?.[1]}
                        </Text>
                    </Flex>
                )}
                <LogoutButton variant="standard" sx={styles.buttons} />
            </Box>
        );
    }

    return (
        <Flex
            w="full"
            data-testid={"authentication"}
            flexDirection={["row"]}
            gap={[0, 0]}
            pl={[3, 0]}
            alignItems="center"
        >
            <Button
                rightIcon={<ECCOIcon name="arrow-right" />}
                as={NextLink}
                href={getPagePathByLocale(createAccountPage, locale)}
                variant={"standard"}
                sx={styles.buttons}
            >
                <span>{getMicrocopy(CommonCMS.global, CommonCMS.button.createAccount)}</span>
            </Button>
            <Button
                rightIcon={<ECCOIcon name="arrow-right" />}
                as={NextLink}
                href={getPagePathByLocale(logInPage, locale)}
                variant={"standard"}
                sx={styles.buttons}
            >
                <span>{getMicrocopy(CommonCMS.global, CommonCMS.button.logIn)}</span>
            </Button>
        </Flex>
    );
}

export default AuthenticationActionBar;
