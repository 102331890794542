import { Box } from "@ui/components/layout/box/Box";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import { CommonCMS } from "@lib/constants/contentful";
import { getPagePathByLocale, homePage, shoppingBagPage } from "@lib/constants/pagePaths";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Container } from "@ui/components/layout/container/Container";
import { ECCOLogo } from "@ui/components/media-and-icons/ecco-logo/ECCOLogo";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useRouter } from "next/router";
import type { FC, ReactNode } from "react";
import CheckOutFooter from "./footer/checkout-footer/CheckOutFooter";
export interface CheckOutLayoutProps {
    siteSettings: ISiteSettings;
    children: ReactNode;
}

export interface CheckOutLayoutNavigationProps {
    bodyBg?: string;
}

export const CheckOutLayoutNavigation: FC<CheckOutLayoutNavigationProps> = ({
    bodyBg,
}): JSX.Element => {
    const { push, locale } = useRouter();
    const { get: getMicrocopy } = useMicrocopy();

    const handleBack = () => {
        push(`${getPagePathByLocale(shoppingBagPage, locale)}`);
    };

    const backText = getMicrocopy(CommonCMS.page, CommonCMS.backText);

    return (
        <Container px={0} maxW="100%" bg={bodyBg} position="sticky" zIndex={10}>
            <Box
                data-testid="checkout-layout"
                mt={[3, 4, 6]}
                mx={[3, 4, 6]}
                style={{ display: "flex", gap: 1 }}
            >
                <NextLink
                    href={getPagePathByLocale(homePage, locale)}
                    alignSelf="center"
                    aria-label={getMicrocopy(CommonCMS.global, CommonCMS.ariaLabels.homeButtonAria)}
                    display="flex"
                    bg="white"
                    h="full"
                    data-testid="homebutton"
                    sx={{
                        display: "flex",
                        height: ["10", "10"],
                        paddingX: ["4"], // Horizontal padding (left and right)
                        paddingY: ["4", "2"],
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    boxShadow="md"
                    _hover={{ color: "gray.500" }}
                >
                    <ECCOLogo />
                </NextLink>
                <Button
                    aria-label="Back Button"
                    data-testid="backbutton"
                    variant="solid"
                    color="black"
                    sx={{
                        display: "flex",
                        height: ["10", "10"],
                        px: "3",
                        py: ["3", "4"],
                        justifyContent: "center",
                        alignItems: "center",
                        gap: ["2", "3"],
                    }}
                    boxShadow="md"
                    onClick={handleBack}
                    bg="white"
                >
                    {backText && (
                        <Text
                            fontSize={["mobileBodyTextSmall", "desktopBodyTextSmall"]}
                            fontWeight="bold"
                        >
                            {backText}
                        </Text>
                    )}
                </Button>
            </Box>
        </Container>
    );
};

export const CheckOutLayout: FC<CheckOutLayoutProps> = ({
    siteSettings,
    children,
}): JSX.Element => {
    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <CheckOutLayoutNavigation bodyBg="gray.50" />
                <Box flex="1" bg="gray.50" pb={[0, 8]}>
                    {children}
                </Box>
                <CheckOutFooter footerData={siteSettings?.fields?.footer} />
            </Box>
        </>
    );
};

export default CheckOutLayout;
