import debounce from "lodash-es/debounce";
import { useState, useCallback } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

export type Rect = Omit<DOMRect, "toJSON">;
type useBoundingRectProps = {
    limit?: number;
    useScrollListener?: boolean;
};

export const getDimensionObject = (node: HTMLElement): Rect => {
    const rect = node.getBoundingClientRect();
    return {
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left,
        x: rect.x,
        y: rect.y,
        right: rect.right,
        bottom: rect.bottom,
    };
};

const initialState = {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    x: 0,
    y: 0,
    right: 0,
    bottom: 0,
};

export const useBoundingRect = <T extends HTMLElement>({
    limit = 100,
    useScrollListener = false,
}: useBoundingRectProps = {}): { ref: (node: T) => void; dimensions: Rect; node: T } => {
    const [dimensions, setDimensions] = useState<Rect>(initialState);
    const [node, setNode] = useState<T>(null!);

    const ref = useCallback((node: T) => {
        setNode(node);
    }, []);

    useIsomorphicLayoutEffect(() => {
        if ("undefined" !== typeof window && node) {
            const measure = () =>
                window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));

            measure();

            const listener = debounce(measure, limit);

            const observer = new ResizeObserver((entries) => {
                entries.forEach(() => {
                    measure();
                });
            });

            observer.observe(node);

            if (useScrollListener) {
                window.addEventListener("scroll", listener);
            }
            return () => {
                if (useScrollListener) {
                    window.removeEventListener("scroll", listener);
                }
                observer.disconnect();
            };
        }
    }, [node, limit]);

    return { ref, dimensions, node };
};
