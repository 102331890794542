import USPbanner from "@components/ui/USPbanner/USPbanner";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { PropsWithChildren, ReactNode } from "react";
import Footer from "./footer/Footer";
import dynamic from "next/dynamic";

const Navigation = dynamic(() => import("./navigation/Navigation").then((mod) => mod.Navigation));

export interface CustomerServiceLayoutProps {
    siteSettings: ISiteSettings;
    children?: ReactNode;
}

export const CustomerServiceLayout = ({
    siteSettings,
    children,
}: PropsWithChildren<CustomerServiceLayoutProps>) => {
    return (
        <>
            {siteSettings?.fields?.uspBanner && (
                <USPbanner uspBanner={siteSettings?.fields?.uspBanner} />
            )}
            <Navigation navigation={siteSettings?.fields?.mainNavigation} />

            {children}
            <Footer footerData={siteSettings?.fields?.footer} />
        </>
    );
};
