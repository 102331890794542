import { listsStyles as styles } from "../styles";
import { Text } from "@ui/components/content/text/Text";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Box } from "@ui/components/layout/box/Box";
import type { FC } from "react";
import { isEmpty } from "lodash-es";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Button } from "@ui/components/forms/button/Button";
import { CommonCMS } from "@lib/constants/contentful";
import { Link } from "@components/cms/link/Link";
import type { ILinksCollection } from "@contentful-api/types/contentful";

export const menuColumns = "menu-columns";
export const groupTitle = "group-title";
export const itemTitle = "item-title";

declare global {
    interface Window {
        showCookieBanner: () => void;
    }
}

interface IMenuColumnsProps {
    linksCollections: ILinksCollection["fields"]["linksCollections"];
}

export const MenuColumns: FC<IMenuColumnsProps> = ({ linksCollections }) => {
    const { get: getMicrocopy } = useMicrocopy();

    if (isEmpty(linksCollections)) return null;

    return (
        <Flex sx={styles.container} data-testid={menuColumns}>
            {linksCollections?.map((link, index) => (
                <Box key={link?.sys?.id} sx={styles.group}>
                    <Text sx={styles.groupCapture} data-testid={groupTitle}>
                        {link?.fields?.title}
                    </Text>
                    {index + 1 === linksCollections.length && (
                        // The footer is something that won't change often, so we can include the renew cookie button here in the last column,
                        // instead of relay on the editors to include it or not.
                        <Button
                            variant={"link"}
                            h="auto"
                            key={link?.sys?.id}
                            data-testid="renewcookieMenubutton"
                            sx={{ ...styles.link, lineHeight: "base" }}
                            onClick={() => window.showCookieBanner()}
                        >
                            <span>{getMicrocopy(CommonCMS.global, CommonCMS.cookieRenew)}</span>
                        </Button>
                    )}
                    {link?.fields?.links?.map((link) => (
                        <Link
                            key={link?.sys?.id}
                            link={link}
                            data-testid={itemTitle}
                            sx={styles.link}
                        />
                    ))}
                </Box>
            ))}
        </Flex>
    );
};
