import type Country from "@interfaces/Country";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import {
    SelectDropdown,
    SelectDropdownButton,
    SelectDropdownItem,
    SelectDropdownList,
} from "@ui/components/overlay/select-dropdown/SelectDropdown";
import { useBreakpointValue } from "@ui/hooks/useBreakpointValue";
import {
    dropdownButtonStyle,
    dropdownItemStyle,
    linkStyle,
    selectDropdownListStyle,
} from "./styles";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useRouter } from "next/router";
import CountryFlagWithName from "./CountryFlagWithName";

interface CountrySelectorProps {
    locationList: Country[];
    currentLocationId: string;
}

export const CountrySelector = ({ locationList, currentLocationId }: CountrySelectorProps) => {
    const { get: getMicrocopy, getMultiple } = useMicrocopy();
    const router = useRouter();
    const microcopies = (() => {
        return getMultiple(CommonCMS.global, [
            CommonCMS.openCountrySelector,
            CommonCMS.countrySelector,
        ]);
    })();

    const getAriaLabelByName = (name: string) => {
        return getMicrocopy(CommonCMS.global, CommonCMS.countrySelectorItem, {
            replacements: {
                countryName: name,
            },
        });
    };

    const currentLocation = locationList.find(({ id }) => id === currentLocationId);
    const isMobile = useBreakpointValue({ base: true, sm: false });
    return (
        <Flex justifyContent="flex-end" alignItems="center" zIndex={2}>
            <SelectDropdown
                eventListeners={{
                    scroll: false,
                }}
                placement={isMobile ? "top-start" : "top-end"}
            >
                {({ isOpen }) => (
                    <>
                        <SelectDropdownButton
                            sx={dropdownButtonStyle}
                            isOpen={isOpen}
                            aria-label={microcopies[CommonCMS.openCountrySelector]}
                        >
                            <CountryFlagWithName
                                icon={currentLocation?.icon}
                                name={currentLocation?.name}
                            />
                        </SelectDropdownButton>
                        <SelectDropdownList
                            aria-label={microcopies[CommonCMS.countrySelector]}
                            p={4}
                            bg="gray.50"
                        >
                            <Box sx={selectDropdownListStyle} role="menu">
                                {locationList.map(({ id, name, icon, link }) => {
                                    const isCurrentDomain = !!link.includes(
                                        process.env.NEXT_PUBLIC_HOSTNAME
                                    );
                                    return (
                                        <SelectDropdownItem
                                            value={id}
                                            key={id}
                                            px={0}
                                            sx={dropdownItemStyle}
                                            aria-label={getAriaLabelByName(name)}
                                        >
                                            <NextLink
                                                sx={linkStyle}
                                                // If it's current domain, we want to keep the current path and just change locale
                                                href={isCurrentDomain ? router.asPath : link}
                                                locale={id}
                                                rel={isCurrentDomain ? "nofollow" : undefined}
                                            >
                                                <CountryFlagWithName icon={icon} name={name} />
                                            </NextLink>
                                        </SelectDropdownItem>
                                    );
                                })}
                            </Box>
                        </SelectDropdownList>
                    </>
                )}
            </SelectDropdown>
        </Flex>
    );
};
