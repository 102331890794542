import type { FC } from "react";
import { useState } from "react";
import { useRouter } from "next/router";
import { ModalTypes } from "@lib/enums/ModalTypes";
import { CommonCMS } from "@lib/constants/contentful";
import { useGlobalModalContext } from "@components/GlobalModalProvider";
import { getPagePathByLocale, homePage } from "@lib/constants/pagePaths";
import type { ButtonProps } from "@ui/components/forms/button/Button";
import { Button } from "@ui/components/forms/button/Button";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";

interface ILogoutButtonProps extends ButtonProps {}

import useSignOut from "@hooks/useSignOut";

export const LogoutButton: FC<ILogoutButtonProps> = (props) => {
    const { locale, route, push } = useRouter();
    const [isInProcess, setIsInProcess] = useState(false);
    const { get: getMicrocopy } = useMicrocopy();
    const { showModal } = useGlobalModalContext();
    const { signOut } = useSignOut();

    const signOutHandler = async () => {
        setIsInProcess(true);
        signOut();

        const homePath = getPagePathByLocale(homePage, locale);
        if (route === homePath) {
            window.scrollTo(0, 0);
        } else {
            await push(homePath);
        }

        return showModal(ModalTypes.signOut);
    };

    return (
        <Button
            variant={"solid"}
            py={2}
            onClick={signOutHandler}
            isLoading={isInProcess}
            {...props}
        >
            <span>{getMicrocopy(CommonCMS.global, CommonCMS.button.logOut)}</span>
        </Button>
    );
};

export default LogoutButton;
