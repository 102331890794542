import type { GridProps as _GridProps, GridItemProps as _GridItemProps } from "@chakra-ui/react";
import { Grid as _Grid, GridItem as _GridItem, forwardRef } from "@chakra-ui/react";

export interface GridProps extends _GridProps {}

export interface GridItemProps extends _GridItemProps {}

export const Grid = forwardRef<GridProps, "div">(({ ...props }, ref) => (
    <_Grid ref={ref} {...props} />
));

export const GridItem = forwardRef<GridItemProps, "div">(({ ...props }, ref) => (
    <_GridItem ref={ref} {...props} />
));
