import type { CSSObject } from "@ui/utils/theme-tools";
import { space } from "@ui/design-tokens";

const boxShadow = "0px 2px 8px rgb(0 0 0 / 20%);";

export const styles: { [a: string]: CSSObject } = {
    marker: {
        height: ["48px", "70px", "70px"],
        width: ["48px", "70px", "70px"],
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        borderRadius: "50%",
    },
    popoverContent: {
        maxW: ["250px", "345px"],
        borderColor: "transparent",
        w: "100%",
        h: "100%",
        /* We need this duplicated property to handle the focus on open/close popover */
        boxShadow: boxShadow,
        _focus: { boxShadow: boxShadow },
        _hover: { color: "initial" },
        _focusVisible: { boxShadow: boxShadow, outline: "none" },
        whiteSpace: "break-spaces",
    },
    popoverProductImage: {
        display: ["none", "block", "block"],
        h: "auto",
        w: "100%",
        maxW: "100px",
        aspectRatio: "4/5",
    },
    popoverProductName: {
        fontSize: ["desktopBodyTextTiny", "desktopBodyTextSmall"],
        fontWeight: "bold",
        textTransform: "uppercase",
        lineHeight: "base",
    },
    popoverTitle: {
        fontSize: ["desktopBodyTextTiny", "desktopBodyTextSmall"],
        fontWeight: "bold",
        textTransform: "uppercase",
        lineHeight: "base",
    },
    popoverDescription: {
        fontSize: ["desktopBodyTextTiny", "desktopBodyTextSmall"],
        fontWeight: "bold",
        lineHeight: "base",
        color: "black",
    },

    badgeDiscountStyle: {
        fontSize: ["mobileBodyTextSmall", "mobileBodyTextSmall", "desktopBodyTextSmall"],
        textAlign: "center",
        textTransform: "capitalize",
        fontWeight: "bold",
        borderWidth: space["0.2"],
        borderStyle: "solid",
        bg: "gray.50",
        color: "black",
        borderColor: "gray.50",
    },
};
