import { Link } from "@components/cms/link/Link";
import {
    linkItemStyles,
    smallHeadingStyles,
    titleLinkStyles,
} from "@components/layouts/navigation/styles";
import type { ILinksCollection } from "@contentful-api/types/contentful";
import { Text } from "@ui/components/content/text/Text";
import { List, ListItem } from "@ui/components/data-display/list/List";
import { Box } from "@ui/components/layout/box/Box";
import { isEmpty } from "lodash-es";
import type { FC } from "react";

interface IDesktopSubNavItemProps {
    title: ILinksCollection["fields"]["title"];
    titleLink?: ILinksCollection["fields"]["titleLink"];
    links: ILinksCollection["fields"]["links"];
}

export const DesktopSubNavItem: FC<IDesktopSubNavItemProps> = ({ title, titleLink, links }) => {
    if (titleLink?.fields) {
        titleLink.fields.label = title;
    }

    return (
        <Box mb={8} data-testid="desktopsubitem">
            {titleLink ? (
                <Link sx={titleLinkStyles} key={titleLink.sys.id} link={titleLink} />
            ) : (
                <Text sx={smallHeadingStyles}>{title}</Text>
            )}
            {!isEmpty(links) && (
                <List>
                    {links?.map((link) => {
                        return (
                            <ListItem key={link?.sys?.id} marginBottom={2}>
                                <Link sx={linkItemStyles} link={link} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};
