import type {
    MenuProps as _MenuProps,
    MenuButtonProps as _MenuButtonProps,
    MenuListProps as _MenuListProps,
    MenuItemProps as _MenuItemProps,
    MenuItemOptionProps as _MenuItemOptionProps,
    MenuGroupProps as _MenuGroupProps,
    MenuOptionGroupProps as _MenuOptionGroupProps,
    MenuDividerProps as _MenuDividerProps,
} from "@chakra-ui/react";
import {
    forwardRef,
    Menu as _Menu,
    MenuButton as _MenuButton,
    MenuItem as _MenuItem,
    MenuItemOption as _MenuItemOption,
    MenuGroup as _MenuGroup,
    MenuOptionGroup as _MenuOptionGroup,
    MenuDivider as _MenuDivider,
    useMultiStyleConfig,
    useMenuContext,
    useMenuPositioner,
    useMenuList,
    useMenuStyles,
    Collapse,
} from "@chakra-ui/react";

import { Button } from "../../forms/button/Button";
import { ECCOIcon } from "../../media-and-icons/ecco-icon/ECCOIcon";
import { chakra, keyframes } from "@chakra-ui/system";
import { cx } from "@chakra-ui/utils";

export interface SelectDropdownProps extends _MenuProps {}

export interface SelectDropdownButtonProps extends _MenuButtonProps {
    isDisabled?: boolean;
}

export interface SelectDropdownListProps extends _MenuListProps {}

export interface SelectDropdownItemProps extends _MenuItemProps {}

export interface SelectDropdownItemOptionProps extends _MenuItemOptionProps {}

export interface SelectDropdownGroupProps extends _MenuGroupProps {}

export interface SelectDropdownOptionGroupProps extends _MenuOptionGroupProps {}

export interface SelectDropdownDividerProps extends _MenuDividerProps {}

export const SelectDropdown = ({ children, ...props }: SelectDropdownProps) => (
    <_Menu gutter={0} {...props}>
        {children}
    </_Menu>
);

const spin = keyframes`
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(180deg)
	}
`;
const spinBack = keyframes`
	from {
		transform: rotate(180deg)
	}
	to {
		transform: rotate(0deg)
	}
`;

const spinAnimation = `${spin} 0.2s linear`;
const spinAnimationBack = `${spinBack} 0.2s linear`;

export const SelectDropdownButton = forwardRef<
    SelectDropdownButtonProps & {
        isOpen?: boolean;
        colorScheme?: string;
        isError?: boolean;
        isDisabled?: boolean;
    },
    "button"
>(({ isOpen = false, sx, ...props }, ref) => {
    const styles = useMultiStyleConfig("SelectDropdown", props);

    // This is a workaround, Chakra only supports accessibility features when the MenuList is direct children of Menu.
    // Because we are using a custom implementation, we need to handle the space bar event manually, to be align with the Menu Button patern: https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/
    const handleSpaceBar = (e: any) => {
        if (e.key === " " || e.keyCode === 32) {
            e.preventDefault();
            e.stopPropagation();
            e.target.click();
            const nextSiblingButtons =
                // Check if there is a React Ref, otherwise pull the HTML ELement
                (
                    (ref as React.MutableRefObject<any>)?.current?.nextSibling ??
                    e.target.nextSibling
                )?.querySelectorAll("button");

            if (nextSiblingButtons) {
                const firstEnabledButton: any = Array.from(nextSiblingButtons).find(
                    (button: any) => !button.disabled
                );

                if (firstEnabledButton) {
                    firstEnabledButton.setAttribute("tabindex", 0);
                    setTimeout(() => {
                        firstEnabledButton.focus({ preventScroll: false });
                    }, 100);
                }
            }
        }
    };

    return (
        <_MenuButton
            ref={ref}
            as={Button}
            onKeyUp={handleSpaceBar}
            disabled={props.isDisabled}
            color={props.isDisabled ? "gray.600" : "black"}
            variant={"outline"}
            colorScheme={"gray"}
            sx={{ ...styles.button, ...sx }}
            h={12}
            rightIcon={
                <ECCOIcon
                    animation={isOpen ? spinAnimation : spinAnimationBack}
                    transform={isOpen ? "rotate(180deg)" : ""}
                    name="down"
                    size={"2xs"}
                    ml={4}
                    willChange="transform"
                />
            }
            {...props}
        />
    );
});
export const SelectDropdownList = forwardRef<SelectDropdownListProps, "div">((props, ref) => {
    const { rootProps, backdropFilter, ...rest } = props;
    const { isOpen } = useMenuContext();

    const ownProps = useMenuList(rest, ref) as any;
    const positionerProps = useMenuPositioner(rootProps);
    const styles = useMenuStyles();

    return (
        <chakra.div
            {...positionerProps}
            __css={{
                zIndex: props.zIndex ?? styles.list?.zIndex,
                backdropFilter: backdropFilter && isOpen ? `blur(10px)` : "",
                backgroundColor: props.bg ?? "whiteTransparent",
                p: props.p ?? 0,
                shadow: "lg",
            }}
        >
            <Collapse
                {...ownProps}
                in={isOpen}
                className={cx("chakra-menu__menu-list", ownProps.className)}
                __css={styles.list}
            />
        </chakra.div>
    );
});

export const SelectDropdownItem = forwardRef<SelectDropdownItemProps, "button">(
    ({ ...props }, ref) => {
        return <_MenuItem ref={ref} {...props} />;
    }
);

export const SelectDropdownItemOption = forwardRef<SelectDropdownItemOptionProps, "div">(
    ({ ...props }, ref) => {
        const styles = useMultiStyleConfig("SelectDropdown", props);
        return <_MenuItemOption ref={ref} sx={styles.item} {...props} />;
    }
);

export const SelectDropdownGroup = forwardRef<SelectDropdownGroupProps, "div">(
    ({ ...props }, ref) => <_MenuGroup ref={ref} {...props} />
);

export const SelectDropdownOptionGroup: React.FC<SelectDropdownOptionGroupProps> = ({
    ...props
}) => <_MenuOptionGroup {...props} />;

export const SelectDropdownDivider: React.FC<SelectDropdownDividerProps> = ({ ...props }) => (
    <_MenuDivider {...props} />
);
