export const listsStyles = {
    container: {
        display: ["flex", "flex", "flex", "flex"],
        fontSize: "sm",
        justifyContent: "space-between",
        flex: 1,
        w: "100%",
        flexWrap: "wrap",
    },
    group: {
        display: "flex",
        flexDirection: "column",
        w: "202px",
        fontWight: "medium",
    },
    groupCapture: {
        fontSize: "sm",
    },
    link: {
        mt: 2.5,
        textDecoration: "none",
        color: "black",
        width: "fit-content",
    },
};

export const accordionStyles = {
    container: {
        w: "100%",
        fontSize: "sm",
    },
    panel: {
        pl: 0,
        py: 1.5,
        color: "gray.800",
        "& a, button": {
            textDecoration: "none",
            color: "gray.200",
        },
    },
    item: {
        bg: "transparent",
    },
    button: {
        pl: 0,
        py: 1.5,
        _expanded: {
            py: 1.5,
        },
        _hover: {
            bg: "transparent",
        },
    },
    buttonText: {
        flex: 1,
        textAlign: "left",
    },
};
