import { Link } from "@components/cms/link/Link";
import {
    categoryTitleMobileStyles,
    mobileButtonBackStyles,
    mobileLinkStyles,
    navItemMobileStyles,
} from "@components/layouts/navigation/styles";
import type { ILinksCollection } from "@contentful-api/types/contentful";
import { Text } from "@ui/components/content/text/Text";
import { Button } from "@ui/components/forms/button/Button";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import space from "@ui/design-tokens/03.space/space";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { motion } from "framer-motion";
import { isEmpty } from "lodash-es";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useState } from "react";

interface IMobileSubNavItemProps {
    title: ILinksCollection["fields"]["title"];
    links: ILinksCollection["fields"]["links"];
}

export const MobileSubNavItem: FC<IMobileSubNavItemProps> = ({ title, links }) => {
    const { getButtonProps, getDisclosureProps, isOpen, onClose } = useDisclosure();
    const { asPath } = useRouter();
    const [hidden, setHidden] = useState(!isOpen);

    /* We need the timeout to close the current submenu, otherwise the load will block the animation */
    useEffect(() => {
        const interval = setTimeout(() => {
            onClose();
        }, 1000);
        return () => clearTimeout(interval);
    }, [asPath, onClose]);

    return (
        <>
            <Flex
                as={Button}
                variant="solid"
                colorScheme="white"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                sx={navItemMobileStyles}
                {...getButtonProps()}
            >
                <Text sx={categoryTitleMobileStyles}>{title}</Text>
                <ECCOIcon
                    color="black"
                    aria-label="Right Arrow"
                    name="chevron"
                    transform="rotate(-90deg)"
                />
            </Flex>
            <motion.div
                {...getDisclosureProps()}
                hidden={hidden}
                initial={false}
                onAnimationStart={() => setHidden(false)}
                onAnimationComplete={() => setHidden(!isOpen)}
                animate={{ width: isOpen ? "100%" : 0 }}
                align={"start"}
                transition={{ ease: "easeOut", duration: 0.15 }}
                style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    position: "absolute",
                    right: "0",
                    height: "calc(100vh - 35px)",
                    top: "0",
                    zIndex: 3,
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 1px",
                }}
            >
                {/* Mobile ipad air view third menu adjust mb to 14+ or so */}
                <Flex bg="white" mb={"4px"}>
                    <Flex
                        as={Button}
                        colorScheme="white"
                        variant="solid"
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        _focus={{ bg: "transparent" }}
                        sx={mobileButtonBackStyles}
                        {...getButtonProps()}
                    >
                        <ECCOIcon
                            color="black"
                            aria-label="Left Arrow"
                            name="chevron"
                            transform="rotate(90deg)"
                        />
                        <Text sx={{ ...categoryTitleMobileStyles, color: "black" }}>{title}</Text>
                    </Flex>
                </Flex>
                <Flex flexDirection={"column"} bg="white" mt={space["1"]} h={"100%"}>
                    {!isEmpty(links) &&
                        links?.map((link) => (
                            <Link link={link} key={link.sys.id} sx={mobileLinkStyles} />
                        ))}
                </Flex>
            </motion.div>
        </>
    );
};
