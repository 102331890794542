import type { INavigation } from "@contentful-api/types/contentful";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Text } from "@ui/components/content/text/Text";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Portal } from "@ui/components/layout/portal/Portal";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { space } from "@ui/design-tokens";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { useOutsideClick } from "@ui/hooks/useOutsideClick";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import RightNavigation from "../components/RightNavigation";
import { desktopNavItemWrapperStyles, rightContainerStyles } from "../styles";
import { AnimatedWrapper } from "./AnimatedWrapper";
import { DesktopNavItem } from "./DesktopNavItem";

export interface DesktopNavProps {
    navigation: INavigation;
}

const SearchBarLazy = dynamic(
    () => import("@components/layouts/navigation/search/SearchBar").then((mod) => mod.SearchBar),
    {
        ssr: false,
    }
);

export const DesktopNav: FC<DesktopNavProps> = ({ navigation }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
    const { asPath, query } = useRouter();
    const [heightNav, setHeightNav] = useState<number>(0);
    const [isChangingRoute, setIsChangingRoute] = useState(false);
    const { get: getMicrocopy } = useMicrocopy();
    const ref = useRef();
    useOutsideClick({
        ref: ref,
        handler: () => setSearchBarOpen(false),
    });

    useEffect(() => {
        onClose();
        setIsChangingRoute(true);
        const timer = setTimeout(() => {
            setIsChangingRoute(false);
        }, 250);
        return () => clearTimeout(timer);
    }, [query.categories, onClose, asPath]);

    useEffect(() => {
        setSearchBarOpen(false);
    }, [asPath, setSearchBarOpen]);

    return (
        <>
            <Flex
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                direction="row"
                align="center"
                justifyContent="center"
                tabIndex={-1}
                data-testid="navPreSearchBox"
                sx={desktopNavItemWrapperStyles}
            >
                {navigation?.fields?.navigationItems?.map((navigationItem) => (
                    <DesktopNavItem
                        isOpen={isOpen}
                        key={navigationItem.sys.id}
                        navigationItem={navigationItem}
                        setHeightNav={setHeightNav}
                        heightNav={heightNav}
                        onOpen={onOpen}
                        onClose={onClose}
                        searchBarOpen={searchBarOpen}
                    />
                ))}
                <AnimatedWrapper isChangingRoute={isChangingRoute} height={heightNav} />
            </Flex>
            <Flex
                ref={ref}
                top={0}
                w="100%"
                gap={space["0.2"]}
                ml={0.2}
                justifyContent="flex-end"
                bg="white"
                onFocus={() => setSearchBarOpen(true)}
                onClick={() => setSearchBarOpen(true)}
            >
                <Flex
                    data-testid="SearchBar"
                    margin={searchBarOpen ? "0 auto" : "0 0 0 0"}
                    width={searchBarOpen ? "100%" : "auto"}
                >
                    <Flex
                        data-testid="SearchBarPlaceholder"
                        alignItems="center"
                        px={3}
                        gap={2}
                        sx={{
                            cursor: searchBarOpen ? "initial" : "pointer",
                            transitionProperty: "common",
                            transitionDuration: "normal",
                            maxW: "200px",
                            _hover: {
                                bg: "gray.100",
                            },
                        }}
                    >
                        <ECCOIcon name="search" />
                        <Text
                            fontWeight="bold"
                            color="black"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            display={["block", "none", "none", "block", "block"]}
                        >
                            {getMicrocopy(CommonCMS.global, CommonCMS.searchBarPlaceholder)}
                        </Text>
                    </Flex>

                    <SearchBarLazy
                        searchBarOpen={searchBarOpen}
                        setSearchBarOpen={setSearchBarOpen}
                    />
                </Flex>
            </Flex>
            <Flex
                sx={{
                    ...rightContainerStyles,
                    opacity: searchBarOpen ? 0 : 1,
                    visibility: searchBarOpen ? "hidden" : "visible",
                    transition: "all .2s ease-in-out",
                }}
            >
                <RightNavigation />
            </Flex>
            <Portal>
                <Box
                    sx={{
                        position: "absolute",
                        backgroundColor: "rgba(0,0,0,0.6)",
                        opacity: isOpen || searchBarOpen ? 1 : 0,
                        visibility: isOpen || searchBarOpen ? "visible" : "hidden",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 3,
                        transition: !isChangingRoute
                            ? "opacity .45s  cubic-bezier(0.85, 0, 0.15, 1), visibility .45s  cubic-bezier(0.85, 0, 0.15, 1)"
                            : "none",
                    }}
                ></Box>
            </Portal>
        </>
    );
};
