import { NextImage } from "@ui/components/media-and-icons/image/NextImage";

export const FallbackImageNavigation = ({ url }) => {
    return (
        <NextImage
            priority={true}
            objectFit={"cover"}
            layout="responsive"
            src={url}
            alt="promo image"
            sizes={["50vw"]}
        ></NextImage>
    );
};
