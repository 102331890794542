const Consent = {
    newsletter: "newsletter",
    privacyPolicy: "privacyPolicy",
    termsAndConditions: "termsAndConditions",
};

export default Consent;

export enum Consents {
    newsletter = "newsletter",
    privacyPolicy = "privacyPolicy",
    termsAndConditions = "termsAndConditions",
}
