import slugify from "slugify";

const productPagePath = require("../../../../apps/web/src/pages/product/_routes.json");

const getCompleteSlug = (slug: string[], basePath: string = ""): string => {
    return [basePath, ...(slug || [])].join("/");
};

export interface PDPSlugParts {
    productName: string;
    articleNumber: string;
    colorCode: string;
    sku: string;
    slug: string;
    distributionChannelWhereClause: string;
}

export const getPDPSlug = (
    productName = "_",
    articleNumber: string,
    colorCode: string,
    locale = "en"
): PDPSlugParts => {
    const distributionChannelId = process.env.NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID;

    if (!distributionChannelId) {
        throw Error("missing NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID");
    }

    return {
        productName,
        articleNumber,
        colorCode,
        sku: `${articleNumber}${colorCode}`,
        slug: getCompleteSlug(
            [slugify(productName?.toLowerCase() || ""), articleNumber, colorCode],
            `/${productPagePath["/"][locale] ?? productPagePath["/"]["en"]}`
        ),
        distributionChannelWhereClause: `productSelection(id=\"${distributionChannelId}\")`,
    };
};
