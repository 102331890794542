import LogoutButton from "@components/auth/LogOutButton";
import {
    logOutStyle,
    pageNavigationScrollerStyles,
} from "@components/cms/page-navigation/PageNavigationStyles";
import type { ILinksCollection } from "@contentful-api/types/contentful";
import { useAuth } from "@lib/auth/useAuth";
import { hasWindow } from "@lib/utils/hasWindow";
import { H } from "@ui/components/content/heading/A11yHeading";
import { Divider } from "@ui/components/data-display/divider/Divider";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { space } from "@ui/design-tokens";
import { useBoundingRect } from "@ui/hooks/useBoundingRect";
import { isEmpty } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { PageNavLink } from "./components/link";
import { useLink } from "./hooks/useLink";

type PageNavigationProps = {
    title?: string;
    pageNavigationLinks: ILinksCollection;
    basePath: string;
    isPrivateDataInTitle?: boolean;
};

export const PageNavigation = ({
    pageNavigationLinks,
    title,
    isPrivateDataInTitle,
}: PageNavigationProps) => {
    const { isAuthenticated } = useAuth();
    const { ref, dimensions, node } = useBoundingRect();
    const [justifyContent, setJustifyContent] = useState<"space-between" | "flex-start">(
        "space-between"
    );
    const activeElementRef = useRef(null);

    const links = pageNavigationLinks?.fields?.links;
    const heading = title || pageNavigationLinks?.fields?.title;

    const { activeLink, onChangeActiveLink } = useLink({ links });

    useEffect(() => {
        if (!hasWindow || isEmpty(node)) return;

        // Check if ref is overflowing
        if (node.scrollWidth > node.clientWidth) {
            setJustifyContent("flex-start");
        } else {
            setJustifyContent("space-between");
        }
    }, [dimensions.width, node]);

    useEffect(() => {
        // Scroll to active element
        // On mobile view at least 3 elements are visible
        if (node && activeElementRef?.current) {
            node.scrollTo({
                left: activeLink > 2 ? activeElementRef.current.clientWidth * (activeLink + 1) : 0,
                behavior: "smooth",
            });
        }
    }, [activeLink, node, activeElementRef?.current]);

    return (
        <Flex w="full" flexDirection="column" bg="gray.50" pl={[3, 3, 6]} pt={[6, 8]}>
            <Divider borderColor="gray.100" display={["block", "block", "none"]} />
            {heading && (
                <Flex
                    justifyContent={justifyContent}
                    alignItems="center"
                    sx={pageNavigationScrollerStyles}
                    ref={ref}
                >
                    <H
                        data-privacy={isPrivateDataInTitle ? true : undefined}
                        fontSize={["mobileH1", "tabletH1", "desktopH1"]}
                    >
                        {heading}
                    </H>
                </Flex>
            )}
            {!heading && isAuthenticated && <LogoutButton />}
            {!isEmpty(links) && (
                <Box overflowX="auto" w="full" pt={[10, 12]} data-testid="PageNavigation">
                    <Flex
                        gap={space["0.2"]}
                        pr={[2, null]}
                        alignItems="center"
                        sx={{
                            ...pageNavigationScrollerStyles,
                            scrollbarWidth: "initial",
                        }}
                        ref={ref}
                    >
                        {links?.map((link, index) => (
                            <PageNavLink
                                key={link?.sys?.id}
                                link={link}
                                isActive={index === activeLink}
                                onClick={onChangeActiveLink(index)}
                            />
                        ))}

                        {isAuthenticated && (
                            <Box data-testid={"logout-desktop"}>
                                <LogoutButton sx={logOutStyle} />
                            </Box>
                        )}
                    </Flex>
                </Box>
            )}
        </Flex>
    );
};
