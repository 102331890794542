import type { FC } from "react";
import { useEffect, useState } from "react";
import type { MarkerComponentProps } from "react-image-marker";
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
} from "@ui/components/overlay/popover/Popover";
import { Button } from "@ui/components/forms/button/Button";
import { styles } from "./styles";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Text } from "@ui/components/content/text/Text";
import { useRouter } from "next/router";
import { Box } from "@ui/components/layout/box/Box";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import type { Modifier } from "@popperjs/core";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import type { PlacementWithLogical } from "@ui/utils/theme-tools";
import type { AlgoliaProduct } from "@lib/wrappers/algolia";

export interface CustomMarkerProps extends MarkerComponentProps {
    description?: {
        [key: string]: string;
    };
    itemNumber: number;
    left: number;
    top: number;
    product: AlgoliaProduct;
    title?: {
        [key: string]: string;
    };
}

export const calculateTop = (placementY) => (placementY === "top" ? 0 : "-100%");

export const calculateLeft = (placement) => {
    if (placement.includes("-start")) {
        return "-50%";
    } else if (placement.includes("-end")) {
        return "50%";
    } else {
        return 0;
    }
};

export const CustomMarker: FC<CustomMarkerProps> = (props: CustomMarkerProps) => {
    const { description, title, left, top } = props;
    const { lg } = useThemeMediaQuery();
    const { onToggle, onOpen, onClose, isOpen } = useDisclosure();
    const { locale } = useRouter();
    const [customInset, setCustomInset] = useState("");
    const placementY = top > 50 ? "top" : "bottom";
    const placementX = left > 33 ? "end" : "start";
    const placement: PlacementWithLogical =
        left > 33 && left < 66 ? placementY : `${placementY}-${placementX}`;

    useEffect(() => {
        if (placement === "bottom-start") {
            return setCustomInset("-50% auto auto 50%");
        }
        if (placement === "bottom-end") {
            return setCustomInset("-50% 50% auto auto");
        }
        if (placement === "bottom") {
            return setCustomInset("-50% auto auto auto");
        }
        if (placement === "top") {
            return setCustomInset("auto auto -50% auto");
        }
        if (placement === "top-start") {
            return setCustomInset("auto auto -50% 50%");
        }
        if (placement === "top-end") {
            return setCustomInset("auto 50% -50% auto");
        }
    }, [placement]);

    const customPosition: Modifier<"customPosition", any> = {
        name: "customPosition",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
            state.styles.popper.inset = customInset;
        },
        effect:
            ({ state }) =>
            () => {
                const popper = state.elements.popper;
                popper.style.inset = customInset;
            },
    };

    return (
        <Popover
            isLazy
            isOpen={isOpen}
            onClose={onClose}
            placement={placement}
            returnFocusOnClose={false}
            modifiers={[customPosition]}
            flip={false}
            gutter={0}
            arrowPadding={0}
        >
            <PopoverTrigger>
                <Button
                    data-testid="popover-trigger-button"
                    className="popover-trigger-button"
                    aria-label="Toggle hotspot"
                    // set onClick on mobile and onMouseEnter on desktop
                    onMouseEnter={lg ? onOpen : undefined}
                    onClick={onToggle}
                    variant="unstyled"
                    sx={{
                        ...styles.marker,
                        border: "1px solid",
                        borderColor: isOpen ? "white" : "rgba(255, 255, 255, 0.3)",
                        zIndex: 1,
                        "::before": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: isOpen ? ["12px", "17.5px"] : ["24px", "35px"],
                            height: isOpen ? ["12px", "17.5px"] : ["24px", "35px"],
                            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "50%",
                            transition:
                                "width 0.25s ease-in, height 0.25s ease-in, border 0.25s ease-in",
                        },
                        ":hover::before": {
                            width: "17.5px",
                            height: "17.5px",
                        },
                    }}
                    h="100%"
                />
            </PopoverTrigger>
            {/* We need a wrapper with this zIndex value, because in the native implementation from Chakra they set Zindex to 10.  */}
            <Box zIndex={isOpen ? 2 : -1} position="absolute" w="100%" h="100%" left={0} top={0}>
                <PopoverContent
                    rootProps={{ style: { transform: "scale(0)" } }}
                    sx={styles.popoverContent}
                >
                    <PopoverBody p={[3, 3]}>
                        <Flex alignItems="flex-start" flexDirection={"column"} gap={1}>
                            {title && (
                                <Text sx={styles.popoverTitle}>{title?.[locale] ?? title?.en}</Text>
                            )}
                            {description && (
                                <Text sx={styles.popoverDescription}>
                                    {description?.[locale] ?? description?.en}
                                </Text>
                            )}
                        </Flex>
                    </PopoverBody>
                    <Box
                        display={["none", "block"]}
                        onMouseLeave={onClose}
                        position={"absolute"}
                        height="175%"
                        width="100%"
                        top={calculateTop(placementY)}
                        left={calculateLeft(placement)}
                    ></Box>
                </PopoverContent>
            </Box>
        </Popover>
    );
};
