import type {
    TagProps as _TagProps,
    TagLabelProps as _TagLabelProps,
    TagCloseButtonProps as _TagCloseButtonProps,
} from "@chakra-ui/react";
import {
    forwardRef,
    Tag as _Tag,
    TagLabel as _TagLabel,
    TagCloseButton as _TagCloseButton,
} from "@chakra-ui/react";

export interface TagProps extends _TagProps {}
export interface TagLabelProps extends _TagLabelProps {}
export interface TagCloseButtonProps extends _TagCloseButtonProps {}

export const Tag = forwardRef<TagProps, "div">(({ ...props }, ref) => (
    <_Tag ref={ref} {...props} />
));

export const TagLabel = forwardRef<TagLabelProps, "div">(({ ...props }, ref) => (
    <_TagLabel ref={ref} {...props} />
));

export const TagCloseButton = forwardRef<TagCloseButtonProps, "button">(({ ...props }, ref) => (
    //@ts-ignore
    <_TagCloseButton ref={ref} {...props} />
));
